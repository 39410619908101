import { components } from '../../../generated/apiTypes';
import { OptionType } from '../ActiveObject/components/ObjectSelect';
import {
  ConstraintsType,
  EqualityConstraint,
  InequalityConstraint,
} from '../types';

const getLabelByTargetMetricsType = (
  type: components['schemas']['PNONetworkOptimizationTargetMetric'],
) => {
  switch (type) {
    case 'Maximum oil debit':
      return 'Максимум нефти';
    case 'Minimum energy consumption':
      return 'Минимум электроэнергии';
    case 'Minimum range energy consumption':
      return 'Минимум разницы расхода ЭЭ';
    case 'Minimum active gas consumption':
      return 'Минимум объема АГ';
    case 'MIN_PRODUCED_FLUID_VOLUME':
      return 'Минимум жидкости';
    case 'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
      return 'Максимум ПНГ';
    case 'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
      return 'Минимум ПНГ';
    case 'MIN_PRODUCED_WATER_VOLUME':
      return 'Минимум воды';
    case 'MIN_SPECIFIC_POWER_CONSUMPTION':
      return 'Минимум УРЭ';
    case 'MIN_SPECIFIC_GASLIFT_CONSUMPTION':
      return 'Минимум УРГГ';
    case 'MIN_TOTAL_GAS':
      return 'Минимум ПНГ+АГ';
    case 'MAX_TOTAL_GAS':
      return 'Максимум ПНГ+АГ';
    case 'LIFTING_COST':
      return 'Минимум LC';
    case 'NET_PRESENT_VALUE':
      return 'Максимум NPV';
    default:
      return '';
  }
};

export const getTargetMetricsTypeOptions = () => {
  const targetMetricsType: components['schemas']['PNONetworkOptimizationTargetMetric'][] =
    [
      'Maximum oil debit',
      'Minimum energy consumption',
      'Minimum range energy consumption',
      'Minimum active gas consumption',
      'MIN_PRODUCED_FLUID_VOLUME',
      'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME',
      'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME',
      'MIN_PRODUCED_WATER_VOLUME',
      'MIN_SPECIFIC_POWER_CONSUMPTION',
      'MIN_SPECIFIC_GASLIFT_CONSUMPTION',
      'MIN_TOTAL_GAS',
      'MAX_TOTAL_GAS',
      'LIFTING_COST',
      'NET_PRESENT_VALUE',
    ];
  const targetMetricsTypeOptions: OptionType<
    components['schemas']['PNONetworkOptimizationTargetMetric']
  >[] = Object.keys(targetMetricsType).map(key => {
    return {
      value: (targetMetricsType as any)[key],
      label: getLabelByTargetMetricsType((targetMetricsType as any)[key]),
      disabled: false,
    };
  });
  return targetMetricsTypeOptions;
};

export const getTargetControlTypeOptions = () => {
  const controlTypes: { value: string; label: string }[] = [
    { value: 'WELL_CONTROL', label: 'Частоты и расходы АГ' },
    { value: 'WELL_OFFSET', label: 'Офсеты' },
    { value: 'WELL_SWITCH', label: 'Переключение' },
    { value: 'WELL_SHUTDOWN', label: 'Выключение' },
  ];
  return controlTypes;
};

export const getConstraintsTypeOptions = () => {
  const constraintsTypes: {
    value: ConstraintsType;
    label: string;
    disabled: boolean;
  }[] = [
    {
      value: 'EmptyNetworkOptimizationExperimentSettingsConstraints',
      label: 'Без ограничений',
      disabled: false,
    },
    {
      value: 'GlobalEqualityNetworkOptimizationExperimentSettingsConstraints',
      label: 'Общее в виде равенства',
      disabled: false,
    },
    {
      value: 'GlobalInequalityNetworkOptimizationExperimentSettingsConstraints',
      label: 'Общее в виде неравенства',
      disabled: false,
    },
    {
      value: 'ClusterEqualityNetworkOptimizationExperimentSettingsConstraints',
      label: 'Кластерные в виде равенств',
      disabled: true,
    },
    {
      value:
        'ClusterInequalityNetworkOptimizationExperimentSettingsConstraints',
      label: 'Кластерные в виде неравенств',
      disabled: true,
    },
  ];
  return constraintsTypes;
};

export const getEqualityConstraintsOptions = () => {
  const options: {
    value: EqualityConstraint;
    label: string;
    disabled: boolean;
  }[] = [
    {
      value: 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Cбор нефти',
      disabled: false,
    },
    {
      value:
        'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Расход электроэн. на УЭЦН-скв',
      disabled: false,
    },
    {
      value:
        'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Расход АГ на газлифтных скв.',
      disabled: false,
    },
    {
      value:
        'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Сбор жидкости',
      disabled: false,
    },
    {
      value:
        'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Сбор ПНГ',
      disabled: false,
    },
    {
      value:
        'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Сбор воды',
      disabled: false,
    },
    {
      value:
        'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'УРЭ для УЭЦН-скв.',
      disabled: false,
    },
    {
      value:
        'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'УРГГ на газлифтных скв.',
      disabled: false,
    },
    {
      value:
        'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Сбор ПНГ+АГ',
      disabled: false,
    },
  ];
  return options;
};

export const getInequalityConstraintsOptions = () => {
  const options: {
    value: InequalityConstraint;
    label: string;
    disabled: boolean;
  }[] = [
    {
      value:
        'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Мин. сбор нефти',
      disabled: false,
    },
    {
      value:
        'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. расход электроэн. на УЭЦН-скв.',
      disabled: false,
    },
    {
      value:
        'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. расход АГ на газлифтных скв.',
      disabled: false,
    },
    {
      value:
        'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. сбор жидкости',
      disabled: false,
    },
    {
      value:
        'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. сбор ПНГ',
      disabled: false,
    },
    {
      value:
        'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. сбор воды',
      disabled: false,
    },
    {
      value:
        'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. УРЭ для УЭЦН-скв.',
      disabled: false,
    },
    {
      value:
        'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. УРГГ на газлифтных скв.',
      disabled: false,
    },
    {
      value:
        'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Макс. сбор ПНГ+АГ',
      disabled: false,
    },
    {
      value:
        'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      label: 'Мин. сбор ПНГ+АГ',
      disabled: false,
    },
  ];
  return options;
};

export const getAFCalibrationTypesOptions = () => {
  const calibrationTypes: {
    value: components['schemas']['PNONetworkFactAdaptationType'];
    label: string;
  }[] = [
    { value: 'FRICTION_FACTOR', label: 'Поправка friction factor' },
    { value: 'HOLDUP', label: 'Поправка holdup factor' },
    { value: 'PRESSURE_SHIFT', label: 'Поправка к давлению' },
  ];
  return calibrationTypes;
};
